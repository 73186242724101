:root {
  --gray-1: #202028;
  --gray-2: #404050;
  --gray-3: #707085;
  --gray-4: #a0a0b8;
  --gray-5: #f0f0ff;
  --lime: #3fff00;
  --main-color-1: #3fff00;
  --main-color-2: #3f00ff;

  background-color: var(--gray-1);

  width: 100%;
  height: 100%;
}

html, body {
  top: 0;
  bottom: 0;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  background-color: var(--gray-1);
  background-color: #ff3f00;

  background: radial-gradient(ellipse at center, var(--gray-2), var(--gray-1));
  /*background-image: url("./images/backgrounds/hexagons.png");*/

  background: linear-gradient(225deg, rgba(25,25,32,1), rgba(64,64,80,0) 70.71%),
                linear-gradient(135deg, rgba(64,64,80,1), rgba(25,25,32,1) 70.71%),
                linear-gradient(45deg,   rgba(32,32,40,1), rgba(0,0,255,0) 70.71%)/*,
                linear-gradient(315deg,   rgba(48,48,60,1), rgba(0,0,255,0) 70.71%)/*,
                url("/public/images/backgrounds/hex-4.png")*/;

  z-index: -100;

  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /*color: gray;*/
}

.name-box {
  margin: 20px;
  margin-top: 30px;
  font-weight: bold;
  width: 100%;

  z-index: 0;  
}

.socials-box {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 66px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  filter: invert(1);
  opacity: 0.7;

  z-index: 1;
}

.socials-image-box {
  width: 60px;
  height: 60px;
  margin-top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid black;
  border-radius: 4px;
}

.socials-image {
  width: 40px;
  height: 40px;
}

.socials-link {
  position: relative;
  width: 60px;
  height: 60px;
  z-index: 1000;
  margin-left: -3px;
  margin-right: -3px;
  
  display: flex;
  align-items: center;
  justify-content: center;
}

.socials-link-span {
  width: 100%;
  height: 400%;
  z-index: 99;
}

.about-me {
  width: max(350px, 50%);
  font-size: 20px;
  margin: 20px;
  margin-top: 0px;

  z-index: 1;
}

.App-link {
  color: var(--gray-1);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-card-shadow-wrapper {
  border-radius: 80px;
  box-shadow:       0 0 5px 5px rgba(0, 0, 0, 0.1),
              inset 0 0 0px 20px rgba(0, 0, 0, 0.1);
  margin: 20px;

  z-index: 1;

}



.skill-card-shadow-wrapper {
  transform: scale(0.6);
  -moz-transform: scale(0.6);
  /*margin-left: -40px;
  margin-right: -40px;*/
  /*margin: -20px -50px;*/
  margin: -12px -42px;
}

.hobby-shadow-wrapper {
  transform: scale(0.6);
  -moz-transform: scale(0.6);
  /*margin-left: -40px;
  margin-right: -40px;*/
  margin: -40px -40px;
  margin: -35px -35px;

}

.main-card {
  background-color: transparent;

  width: 350px;
  height: 510px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 20px dashed var(--gray-3);
  border-radius: 80px;

  box-shadow: inset 0 0 5px 5px rgba(0, 0, 0, 0.1);
  z-index: 2;




  background-color: var(--gray-5);
  border: 20px dashed var(--gray-5);
  color: var(--gray-1);
  box-shadow: inset 0 0 5px 5px rgba(0, 0, 0, 0.0);
  
}

@keyframes card-wire-animation {
  5% { 
    background-color: var(--gray-5);
    border: 20px dashed var(--gray-5);
    color: var(--gray-1);
    box-shadow: inset 0 0 5px 5px rgba(0, 0, 0, 0.0);

  }
  15% {
    background-color: transparent;
    border: 20px dashed var(--gray-3);
    color: var(--gray-5);
    box-shadow: inset 0 0 5px 5px rgba(0, 0, 0, 0.1);

  }
  
  30% { 
    background-color: transparent;
    border: 20px dashed var(--gray-3);
    color: var(--gray-5);  
    box-shadow: inset 0 0 5px 5px rgba(0, 0, 0, 0.1);

  }
  40% { 
    background-color: var(--gray-5);
    border: 20px dashed var(--gray-5);
    color: var(--gray-1);
    box-shadow: inset 0 0 5px 5px rgba(0, 0, 0, 0.0);

  }
}

.main-card-animation {
  animation-name: card-wire-animation;
  animation-duration: 25.4s;
  animation-timing-function: ease;
  animation-delay: 3s;
  animation-iteration-count: infinite;
  /*animation-direction: alternate;*/
}

.card-animation-1 {
  animation-delay: 1s;
}

.card-animation-2 {
  animation-delay: 4s;
}

.card-animation-3 {
  animation-delay: 7s;
}

.card-animation-4 {
  animation-delay: 9s;
}

.card-animation-5 {
  animation-delay: 10s;
}

.card-animation-6 {
  animation-delay: 11.2s;
}

.card-animation-7 {
  animation-delay: 12.5s;
}

.card-animation-8 {
  animation-delay: 13s;
}

.card-animation-9 {
  animation-delay: 14.3s;
}

.card-animation-10 {
  animation-delay: 5.4s;
}



.card-box {
  

  background: linear-gradient(150deg, rgba(65,65,82,.5), rgba(64,64,80,0) 52.71%),
              linear-gradient(270deg, rgba(104,104,124,.5), rgba(25,25,32,0) 50.71%),
              linear-gradient(30deg,  rgba(72,72,90,.5), rgba(0,0,0,0) 50.71%);

  border-radius: 15px;
  margin: 35px;
  margin-top: 15px;
  margin-bottom: 20px;

  /*color: var(--background-color);*/
  color: var(--text-1);
  /*text-shadow: 0 6px 7px #000000;*/

  padding: 20px;
  width: calc(100% - 50px);
  box-shadow: 0 30px 20px -20px rgba(0, 0, 0, 0.7),
              0 0 10px 1px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  left: 25px;

  z-index: 1;


}

.skills-box {
  background: linear-gradient(290deg, rgba(65,65,82,.5), rgba(64,64,80,0) 52.71%),
              linear-gradient(170deg, rgba(104,104,124,.5), rgba(25,25,32,0) 50.71%),
              linear-gradient(50deg,  rgba(72,72,90,.5), rgba(0,0,0,0) 50.71%);
}

.interests-box {
  background: linear-gradient(310deg, rgba(65,65,82,.5), rgba(64,64,80,0) 52.71%),
              linear-gradient(185deg, rgba(104,104,124,.5), rgba(25,25,32,0) 30.71%),
              linear-gradient(70deg,  rgba(72,72,90,.5), rgba(0,0,0,0) 50.71%);
}

.skill-card {
  height: 150px;
  width: 300px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.hobby-card {
  height: 280px;
  width: 280px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.section-title {
  font-size: 1.5em;
  font-weight: bold;
  width: 100%;
  margin: 10px;
  margin-bottom: 25px;
}

.project-image-container {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}

.project-image {
  width: 200px;
  height: 200px;


  margin-top: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

}

.kristi-caterson-image {
  overflow: hidden;

  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  	animation: droplet-animation 12s ease-in-out alternate infinite;
	z-index: 2;
}


@keyframes droplet-animation {
	0% {
		border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
	}
	25% { 
		border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
	}
	50% {
		border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
	}
	75% {
		border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
	}
}

.skill-image {
  position: absolute;
  left: 15px;
  width: 100px;
  height: 100px;
  margin: 0px;
  margin-top: 5px;
  margin-left: -10px;
  padding: 0px;
  border-radius: 50px 50px 0px 50px;
  overflow:hidden;
}

.hobby-image {
  width: 150px;
  height: 150px;

  margin-top: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.node-image {
  border-radius: 100px;
}

.aws-image {
  width: 55%;
  margin: 0px;
  margin-left: -25px;
}

.fastai-image {
  border-radius: 0px;
  margin-top: 5px;
  margin-left: 10px;
  width: 75px;
}

.pytorch-image {
  border-radius: 0px;
  width: 80px;
  margin-left: -5px;
}


.music-image {
  width: 180px;

}


.card-title {
  font-weight: bold;
}

.card-text {
  height: 80px;
}

.skill-card-title {
  font-size: 1.7em;
  margin: 0px;
  margin-left: 100px;

  display: flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: flex-start;
}

.hobby-card-title {
  font-size: 25px;
}

.skill-text-box {
  display: flex;
  flex-wrap:wrap;
  align-items: flex-start;
  justify-content: center;
  height: 80px;
  width: 300px;
  margin-top: -15px;
  margin-bottom: -15px;
}

.skill-text {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 10, 0.2);
  border-radius: 100px;
  margin: 4px;


  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;

}

.skill-card-body {
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0px;
  
}




.card-button {
  position: relative;
  bottom: 10px;
  background-color: var(--gray-2);
  color: var(--gray-5);
  margin-top: 30px;
  border: 1px groove rgba(50, 220, 0, 1);
  border-radius: 50px;
  transition: all .5s ease-out;
  /*box-shadow: 0 0 5px 0 rgba(0, 0, 0, 1);*/
}

.card-button:hover, .card-button:focus, .card-button:active {
  background-color: var(--lime);
  color: var(--gray-2);

  border: 1px groove rgba(220, 220, 240, 1);

}


.bottom-section {
  color: var(--gray-5);

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  z-index: 1;
}

.bottom-link-wrapper {
  background-color: var(--gray-5);
  font-weight: bold;
  color: var(--gray-1);
  width: 90px;
  height: 30px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50px;

  transition: all .3s ease-out;

}

.bottom-link-wrapper:hover {
  color: var(--gray-3);
  font-size: 17px;
}

.bottom-link-text {
  font-weight: bold;
  color: var(--gray-5);
  width: 100px;
  height: 30px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

}



@keyframes bottom-link-keyframes {
  0% { 
    font-size: 17px;

  }
  
  100% { 
    font-size: 18px;

  }
}

.bottom-link-animation:hover {
  /*animation-name: bottom-link-keyframes;
  animation-duration: .3s;
  animation-timing-function: ease;
  animation-delay: 0s;
  font-size: 18px;*/

}




.fade-in {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 2s; /* Firefox < 16 */
      -ms-animation: fadein 2s; /* Internet Explorer */
       -o-animation: fadein 2s; /* Opera < 12.1 */
          animation: fadein 2s;
  
  animation-timing-function: ease;
}

.fade-in:target {
  
}

@keyframes fadein {
  from { 
    opacity: 0; 
    transform: translateY(100px);
    
  }
  to   { 
    opacity: 1;
    transform: translateY(0%);
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}





@media (max-width: 500px) {
  .main-card-shadow-wrapper-responsive {
    /*transform: scale(0.85);
    -moz-transform: scale(0.85);*/
    width: 300px;
    height: 540px;
  }

  .main-card-responsive {
    width: 300px;
    height: 540px;
  }

  .socials-box {
    position: relative;
    /*width: 195px;*/
    width: 0px;
    height: 0px;
    clip-path: none;
    opacity: 0;
  }
  .socials-image-box {
    /*margin: 2px;*/
    margin: 0;
  }
}


.starter-box {
    height: 100vh;
    width: 100vw;
}



/* ================================================================
*/